export default function AddYourHolidaysIcon() {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.5" cy="21.5" r="21.5" fill="#EAEAEF" />
      <path
        d="M24.2656 21.625L21.7656 28.5H32H33.25V31H32H12H10.75V28.5H12H19.1094L21.9219 20.7656L24.2656 21.625ZM28.9922 21.625C29.7344 19.5156 30.125 17.3281 30.0469 15.1016V14.8672C30.0078 14.5156 29.9688 14.2031 29.8906 13.8906C32.4688 16.2734 33.6406 19.9062 32.8203 23.4219L28.875 21.9766L28.9922 21.625ZM28.7969 14.9062V15.1406C28.8359 17.2109 28.5234 19.2422 27.8203 21.1953L27.7031 21.5469L19.4609 18.5781L19.5781 18.1875C20.3203 16.2734 21.375 14.4766 22.7422 12.9141L22.8984 12.7578C23.8359 11.7422 25.2422 11.3516 26.5312 11.8203C26.5703 11.8203 26.6094 11.8594 26.6484 11.8594C27.8984 12.3672 28.7188 13.5391 28.7969 14.9062ZM14.1875 16.625C15.8672 13.3438 19.2266 11.3125 22.7812 11.1953C22.4688 11.3906 22.2344 11.6641 21.9609 11.8984L21.8047 12.0938C20.3203 13.7344 19.1875 15.6875 18.4062 17.7578L18.2891 18.1484L14.1875 16.625Z"
        fill="#8EA5DB"
      />
    </svg>
  );
}
