import { useLocation } from '@remix-run/react';
import localforage from 'localforage';
import React from 'react';
import { utmParamsKey } from '~/constants/cache';

export interface ParamsMap {
  [key: string]: string;
}

const transformedParamKey: ParamsMap = {
  utm_medium: 'm',
  utm_source: 's',
  utm_campaign: 'n',
};

export default function useStoreParams() {
  const location = useLocation();

  React.useEffect(() => {
    const storeQueryParams = async () => {
      const params = new URLSearchParams(location.search);
      const entries: ParamsMap = {};
      params.forEach((value, key) => {
        const transformed = transformedParamKey[key];
        if (transformed) {
          entries[transformed] = value;
        }
      });

      await localforage.setItem(utmParamsKey, entries);
    };

    if (location.search) storeQueryParams();
  }, [location.search]);
}
