import { ActionFunctionArgs, json, type LoaderFunctionArgs, type MetaFunction } from '@remix-run/node';
import Footer from '../components/route/Footer';
import useStoreParams from '../hooks/useStoreParams';
import LandingPageImage from '~/components/landingPage/Image';
import LandingPageContent from '../components/landingPage/LandingPageContent';
import { commitSession, getSession, logout } from '~/sessions';
import { useLoaderData, useSubmit } from '@remix-run/react';
import { NOTIFICATION, TOKEN } from '~/constants/cookies';
import Notification from '~/components/notifications/Notification';
import NavBar from '~/components/navBar/NavBar';
import { getUserProfile } from '~/utils/getUserProfile';

export const meta: MetaFunction = () => {
  return [
    { title: 'Custody Navigator: Create Parenting Plans & Schedules | Co-Parenting Tools & Resources' },
    {
      name: 'description',
      content:
        'Custody Navigator helps co-parents create comprehensive parenting time schedules with less effort. Access user-friendly tools and templates to create your custody arrangements smoothly.',
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request);
  const notification = session.get(NOTIFICATION) || null;
  const token = session.get(TOKEN);
  if (token) {
    const response = await getUserProfile(token);
    if (response)
      return json(
        { isAuthenticated: true, name: response.name, notification, scheduleExists: response.scheduleExists },
        {
          headers: {
            'Set-Cookie': await commitSession(session),
          },
        }
      );
  }

  return json(
    {
      isAuthenticated: false,
      name: '',
      notification,
      scheduleExists: false,
    },
    {
      headers: {
        'Set-Cookie': await commitSession(session),
      },
    }
  );
}

export async function action({ request }: ActionFunctionArgs) {
  const session = await getSession(request);
  const formData = await request.formData();
  const intent = formData.get('intent');
  if (intent === 'logout') return logout({ session });
}

export default function Index() {
  const { isAuthenticated, name, notification, scheduleExists } = useLoaderData<typeof loader>();
  useStoreParams();
  const submit = useSubmit();
  const handleLogout = () => {
    submit({ intent: 'logout' }, { method: 'POST' });
  };

  return (
    <div className="flex min-h-screen flex-col justify-between bg-neutral-offWhite">
      <NavBar isAuthenticated={isAuthenticated} onLogout={handleLogout} userData={{ name, scheduleExists }} />
      <div className="grow">
        <div className="mx-auto my-0 flex max-w-screen-sm flex-col justify-start px-4 pb-4 screen-md:max-w-[1075px] screen-md:pb-8 screen-lg:max-w-[1281px]">
          <div className="mx-auto flex w-full flex-col items-center justify-center gap-2 screen-md:flex-row-reverse">
            <LandingPageImage />
            <LandingPageContent />
          </div>
        </div>
      </div>

      <div className="pb-2">
        <Footer />
      </div>

      <Notification notification={notification} />
    </div>
  );
}
