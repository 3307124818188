import LargeScreenContent from './LargeScreenContent';
import SmallScreenContent from './SmallScreenContent';

export default function LandingPageContent() {
  return (
    <>
      <LargeScreenContent />
      <SmallScreenContent />
    </>
  );
}
