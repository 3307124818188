import AddDetailsIcon from '../icons/AddDetailsIcon';
import AddYourHolidays from '../icons/AddYourHolidays';
import PickTemplateIcon from '../icons/PickTemplateIcon';

const features = [
  { description: 'Pick a template', icon: <PickTemplateIcon /> },
  { description: 'Add your details', icon: <AddDetailsIcon /> },
  { description: 'Set your holidays', icon: <AddYourHolidays /> },
];

export default function FeatureList() {
  return (
    <ol>
      {features.map(({ description, icon }) => (
        <li
          key={description}
          className="border-taupe flex items-center justify-start border-b border-dashed py-4 screen-md:py-2"
        >
          <span>{icon}</span>
          <span className="text-brown-dark pl-4 text-base font-light screen-lg:text-[23px]">{description}</span>
        </li>
      ))}
    </ol>
  );
}
