import { Link } from '@remix-run/react';
import { Button } from '../ui/button';
import { PageRoutes } from '~/constants/routes';
import CaretRightCircleIcon from '../icons/CaretRightCircleIcon';
import FeatureList from './FeatureList';

export default function SmallScreenContent() {
  return (
    <div className="w-full pt-2 screen-md:hidden">
      <div className="bg-beige-light-20 border-taupe w-full rounded-xl border px-4 py-6">
        <h2 className="text-green-primary-text text-[32px] font-bold">
          <span className="flex flex-col items-start">
            <span className="text-green-primary-text">Ready to find the right </span>
            <span className="bg-green-light-40 text-green-light inline-flex w-fit rounded-lg px-1 py-0">
              parenting schedule?
            </span>
          </span>
        </h2>
        <h3 className="py-4 text-lg font-light">Just follow three easy steps to map out some simple schedules.</h3>
        <Button asChild variant="primary">
          <Link to={PageRoutes.TEMPLATES} className="flex items-center justify-center">
            <span className="text-neutral-white pr-2">Step 1: Pick a schedule template</span>
            <CaretRightCircleIcon />
          </Link>
        </Button>
      </div>
      <div className="flex flex-col px-4">
        <h2 className="text-green-primary-text pb-4 pt-6 text-[28px] font-bold">
          <span className="flex flex-col items-start">
            <span className="text-green-primary-text">From overwhelmed </span>
            <span className="flex items-center justify-start">
              to <span className="bg-green-light-40 text-green-light ml-1 w-fit rounded-lg px-1 py-0">in control</span>
            </span>
          </span>
        </h2>

        <div className="border-taupe w-full border-b border-dashed pt-2" />
        <FeatureList />
      </div>
    </div>
  );
}
