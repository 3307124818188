export default function PickTemplateIcon() {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.5" cy="21.5" r="21.5" fill="#F5D9D3" />
      <path
        d="M19.5 11V13.5H24.5V11H27V13.5H30.75V17.25H13.25V13.5H17V11H19.5ZM13.25 18.5H30.75V31H13.25V18.5ZM26.1016 22.9141L26.7656 22.25L25.4375 20.9609L24.7734 21.625L21.0625 25.3359L19.2266 23.5L18.5625 22.8359L17.2344 24.125L17.8984 24.7891L20.3984 27.2891L21.0625 27.9531L21.7266 27.2891L26.1016 22.9141Z"
        fill="#E77F7E"
      />
    </svg>
  );
}
