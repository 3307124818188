export default function AddDetailsIcon() {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.5" cy="21.5" r="21.5" fill="#FAE5D3" />
      <path
        d="M17.625 21.625L25.2812 13.9688L29.0312 17.7188L21.375 25.375L17 26L17.625 21.625ZM32 14.75L29.9297 16.8203L26.1797 13.0703L28.25 11L32 14.75ZM13.25 13.5H19.5H20.75V16H19.5H14.5V28.5H27V23.5V22.25H29.5V23.5V29.75V31H28.25H13.25H12V29.75V14.75V13.5H13.25Z"
        fill="#FD8E3F"
      />
    </svg>
  );
}
